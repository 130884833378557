// Core

@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.6.0) components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";

body{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5)!important;
}

.table, .collapsible-header, .tab-nav ul li, .list-order{
  color: rgba(0, 0, 0, 0.85)!important;
}

.bg-default{
  background: #f5f5f5!important;
}

.bg-secondary{
  background: #fff!important;
}

.bg-gradient-default{
  background: linear-gradient(87deg,#172b4d,#1a174d)!important;
}

.datepicker-chart .input-group-text, .datepicker-chart input {
  background: #18224d;
  border: 1px solid #18224d;
}

.full-width{
  width: 100%;
}

.loadLoading{
  width: 100%;
  padding: 20px
}

.load-more-btn{
  border: solid 1px #e9ecef;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background: #fbfdff;
  border-radius: 5px;
}

.text-center{
  text-align: center!important;
}

.table td, .table th {
  font-size: 12px;
  white-space: nowrap;
}
.text-sm, .badge-dot {
  font-size: 12px !important;
}
.form-checkbox{
  padding-left: 20px;
  padding-top: 20px;
}
.bMAizI{
  top: 0!important;
  right: -10px!important;
  font-size: 10px;
  width: 10px;
  height: 10px;
}
.bMAizI svg{
  width: 20px!important;
}
.sc-bdnxRM{
  margin-top: 0!important;
}
.avatar-user{
  margin: auto;
  display: table;
}
.form-check-label-cod{
  padding-left: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-20{
  margin-bottom: 20px;
}
.mt-20{
  margin-top: 20px;
}
.full-address-box{
  background: #f5f5f5;
  padding: 10px 10px;
  border-radius: 5px;
}
.autocomplete-field input:not([type]):focus:not([readonly]), .autocomplete-field input[type=text]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=password]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=email]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=url]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=time]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=date]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=datetime]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=datetime-local]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=tel]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=number]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]){
  border: 0;
  box-shadow: none;
}
.btn-right-label{
  float: right;
  font-size: 10px;
  right: 0;
  position: absolute;
  padding-right: 15px;
  color: #847c7c;
}
.btn-right-label2 {
  float: right;
  font-size: 10px;
  right: 0;
  position: absolute;
  padding-right: 10px;
  color: #847c7c;
}
.btn-right-label .material-icons, .btn-right-label2 .material-icons{
  font-size: 11px;
}
.sc-ifAKCX{
  padding-top: 5px;
}
.jZkBQk > .wrapper{
  border-radius: 0;
  z-index: 999!important;
}
.label-form-group{
  font-size: 12px;
}
.btn-white{
  background: white;
  color: black;
}
.editor-text{
  height: 400px!important;
}
.avatar-user-sidebar{
  width: 100%;
}
.avatar-user-sidebar div{
  width: 100%!important;
  height: 300px!important;
}
.avatar-user-sidebar svg{
  margin: auto;
  display: table;
  margin-top: 100px;
}
.avatar-user-sidebar img{
  margin: auto;
  display: table;
  width: auto!important;
}
.avatar-user-sidebar2 img{
  margin: auto;
  display: table;
  width: 100%!important;
}
.sc-gKAaRy svg{
  margin-top: 10px;
  margin-right: 20px;
}
.pointer{
  cursor: pointer;
}
.back-grey{
  background: #f8f9fe;
}
.list-order{
  padding: 20px;
  border-top: solid 1px #f5f5f5;
  font-size: 12px;
  width: 100%;
}
.list-order a{
  color: #525f7f!important;
}
.list-order .form-group{
  margin-bottom: 0;
}
.li-order-item{
  background: #fff9f9;
  padding: 5px;
  list-style: none;
  border: solid 1px #f5f5f5;
  border-radius: 0;
  margin-top: 4px;
}
.table-order{
  width: 100%;
}
.label{
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 2px;
  color: #444;
  margin-bottom: 1px;
  background: white;
  border: solid 1px #ccc;
}
.label-info{
  background: #11cdef;
  color: white;
}
.label-error{
  background: red;
  color: white;
}
.label-success{
  background: green;
  color: white;
}
.modal-body{
  font-size: 12px;
}
.pt-0{
  padding-top: 0;
}
.chart2{
  height: 390px;
}
.datepicker-chart input{
  // background: #18224d;
  // border:solid 1px #18224d;
  // color: white;
}
.datepicker-chart .input-group-text{
  // background: #18224d;
  // border:solid 1px #18224d;
}
.card-header{
  font-weight: 600;
}
.bg-grey{
  background: #444;
}
.img-factor{
  width: 100%;
}
.pull-right{
  float: right;
}
.table td, .table th{
  font-size: 11px;
}
.card .table td, .card .table th {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.fa-big{
  font-size: 20px;
  cursor: pointer;
}
.navbar-nav li ul {
  // display: none;
  background: #FFFFFF;
  list-style: none;
}
.navbar-nav li ul li a{
  padding-left: 5px!important;
}

.navbar-nav li ul li a:before{
  border: 0!important;
}

.input-group{
  margin-bottom: 10px;
}

.m-b20{
  margin-bottom: 20px!important;
}

.card-cart {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  background: none;
  padding: 10px;
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
  width: 100%;
  border-radius: 0;
}

.card-cart .form-control{
  margin-bottom: 10px;
}

.card-cart .autocomplete{
  margin-bottom: 20px;
}

.full-address-box{
  width: 100%;
}

.input-field{
  padding-top: 5px;
  padding-bottom: 5px;
}

.input-field2{
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  background: white;
}

.input-field3{
  padding: 10px 25px;
  background: white;
}

.on-total{
  font-size: 20px;
  font-weight: 20px;
  width: 100%;
  text-align: center;
}

.btn-chart{
  width: 100%;
  border-radius: 0;
}

.add-cart-button{
  cursor: pointer;
  background: #5e72e4;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-top: 5px;
  margin: auto;
  display: table;
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 10px;
}

.add-cart-button .fa{
  margin-top: 8px;
}
.modal-widget-box-chart{
  width: 100%;
}
.card.horizontal {
  display: flex;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
}
.card-flex{
  flex-direction: inherit;
}
.card.horizontal .card-image {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 10px;
}
.card.horizontal .card-image img{
  width: 100%;
  height: 100%;
}
.card.vertical .card-image{
  position: relative;
  height: 180px;
  overflow: hidden;
  margin-bottom: 10px;
}
.title-card-label{
  margin-bottom: 10px;
}
.btn-increament{
  border-radius: 0;
  background: #5061c5;
  border: solid 1px #5061c5;
  max-height: 43px;
}
.btn-delete{
  border-radius: 0;
  background: red;
  color: white;
  border: solid 1px red;
  font-size: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-right: 0!important;
  float: right;
}
.btn-delete:hover{
  color: white;
}
.btn-edit{
  border-radius: 0;
  background: white;
  color: #444;
  border: solid 1px #ccc;
  font-size: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-right: 0!important;
  float: right;
}
.btn-edit:hover{
  color:#444;
}
.m-top-10{
  margin-top: 10px;
}
.discount-price{
  color: red;
  text-decoration: line-through red;
  font-size: 10px;
}
.add-chart-seaction{
  margin-bottom: 10px;
}
.padding0{
  padding: 0;
}

.image-gallery-list{
  width: 90px;
  height: 90px;
  float: left;
  margin: 3px;
  position: relative;
}

.image-gallery-delete{
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  font-size: 20px;
  width: 20px;
  height: 23px;
  cursor: pointer;
}

.image-gallery-list img{
  width: 100%;
  height: 100%;
}

.image-gallery-list .avatar-user-sidebar div{
  height: 90px!important;
  width: 100%;
}

.image-gallery-list .avatar-user-sidebar svg{
  margin-top: 10px;
}

.dFVzTm{
  box-shadow: none!important;
}

.image-galleries{
  width: 100%;
  display: inline-block;
}

.bg-gradient-info-new {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.bg-info-new {
  background-color: #1963da;
  color: white;
}

.btn-info, .btn-info:hover, .btn-info:focus{
  background-color: #1963da;
  border: solid 1px #1963da;
  color: white;
}

.text-blue-new {
  color: #5e72e4;
}

.btn-primary{
  border: 0;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: white;
  background-color: #c4c4cd;
}

.nav-pills .nav-link, .nav-pills .nav-link:hover {
  color: rgba(0, 0, 0, 0.8);
}

.navbar-vertical .navbar-nav .nav-link > i{
  color: #1963da;
}

.bg-info{
  background-color: #1963da!important;
}

.jvCTkj{
  background-color: #ccc!important;
}

.input-group .input-group{
  margin-bottom: 0!important;
}

.dFVzTm {
  background-color: #ccc!important;
}

.bg-gradient-new{
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.well{
  background: #f5f5f5;
  padding: 10px;
  border: dashed 1px#ccc;
}

.lp_section1{
  background: #f5f5f5;
  min-height: 400px;
  margin-bottom: 40px;
  .img{
    background: #f5f5f5;
    height: 100%;
    width: 100%;
  }
}

.lp_section2{
  background: #fff;
  margin-bottom: 40px;
  .img{
    background: #f5f5f5;
    height: 250px;
    width: 100%;
    margin-bottom: 20px;
  }
}

.lp_section3{
  height: 400px;
  margin-bottom: 40px;
  .img{
    background: #f5f5f5;
    height: 400px;
    width: 100%;
  }
}

.btn-wa {
  padding: 2px 5px;
  background: green;
  color: white;
  border-radius: 10px;
}

.btn-wa:hover {
  color: white;
  opacity: 0.9;
}

.tab-nav ul::-webkit-scrollbar {
  display: none;
}
.tab-nav ul{
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
}
.tab-nav ul li{
  border-bottom: solid 4px white;
  padding: 10px 14px;
  font-size: 11px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.tab-nav ul li:hover{
  border-bottom: solid 4px #f5f5f5;
}
.tab-nav ul li.active{
  border-bottom: solid 4px #f5f5f5;
}
.margin-none{
  margin: 0;
  padding: 0;
}

.collapsible-body{
  display: block;
}

.collapsible-header{
  background: #f5f5f5;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}

.collapsible-body{
  padding: 0;
  padding-left: 20px;
  border: 0;
}

.list-provinces{
  padding: 0;
  list-style: none;
  margin-bottom: 50px;
}

.order-print-box{
  min-height: 200px;
  width: 100%;
  border: solid 1px #000;
  margin-bottom: 10px;
  .order-print-box-header{
    padding: 10px;
    border-bottom: solid 1px #000;
  }
  .order-print-box-body{
    padding: 10px;
    text-align: center;
  }
  .order-print-box-code{
    padding: 5px;
    border: solid 1px #000;
    margin-bottom: 10px;
  }
  .col-4{
    text-align: center;
  }
}

.box-page-break{
  width: 100%;
  float: left;
  position: relative;
  // page-break-inside:auto;
  page-break-inside: avoid;
  page-break-after:auto;
  // page-break-after: always;
  // page-break-before:always;
  margin-bottom: 25px;
  min-height: 480px;
}

.cover-image{
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  height: 170px;
  overflow: hidden;
  border: solid 1px #fcfcfc;
  background: #e9eef1;
  img{
    height: 100%;
    position: absolute;
    left: -999px;
    right: -999px;
    margin: auto;
    display: table;
  }
  .cover-stock{
    position: absolute;
    left: 0;
    bottom: 0;
    background: white;
    padding: 2px 6px;
    font-size: 10px;
  }
}

.cover-discount{
  background: red;
  color: white;
  padding: 2px;
  font-size: 9px;
}

.input-group-alternative{
  border: solid 1px #ccc;
  box-shadow: none;
}

.navbar-dark .navbar-nav .nav-link, .navbar-dark:hover .navbar-nav:hover .nav-link:hover{
  color: rgba(0, 0, 0, 0.9)!important;
}

.h1-section{
  color: rgba(0, 0, 0, 0.9)!important;
}

.card{
  box-shadow: 0 2px 5px rgba(50, 50, 93, 0.01), 0 3px 6px rgba(0, 0, 0, 0.02);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.9);
}

.gNmhIT{
  margin-top: 0!important;
}

@media print {
  body {
    visibility: hidden;
  }

  html {
    height: 0;
  }

  .hide-print{
    visibility: hidden!important;
  }

  .to-print, .to-print * {
    visibility: visible;
  }

  .to-print {
    position: absolute;
    left: -260px!important;
    top: -590px!important;
    width: auto;
    clear: both;
    page-break-after: always;
    page-break-before:always;
  }

  .to-print2, .to-print2 * {
    visibility: visible;
  }

  .to-print2 {
    position: absolute;
    left: -260px!important;
    top: -100px!important;
    width: auto;
    clear: both;
    page-break-after: always;
    page-break-before:always;
    box-shadow: none!important;
    border: solid 1px #ccc;
  }

  footer {page-break-after: always;}
  pre, blockquote {page-break-inside: avoid;}
}

.sidebar-hide .navbar-vertical{
  max-width: 60px;
}

.sidebar-hide .main-content{
  margin-left: 60px!important;
}

.navbar-nav li ul{
  padding-left: 20px;
}

.navbar-nav li ul li a{
  color: #887878 !important;
  font-size: 0.8rem!important;
}
.navbar-nav li ul li a i{
  color: #ccc!important;
  font-size: 0.8rem!important;
}
.navbar-nav li ul li a.active, 
.navbar-nav li ul li a.active i{
  color: #000!important;
}
.nav-active-board{
  display: block!important;
  padding: 0;
  left: -8px;
  position: relative;
}
.nav-active-board-left{
  float: left;
}
.navbar-vertical.navbar-expand-md .navbar-brand-img {
  max-height: 2rem;
}

// .box-image-select{
//   width: 100%;
//   display: inline-block;
//   div{
//     // width: 100%!important;
//     // height: auto!important;
//     // position: relative;
//     // display: block;
//     // min-height: 200px;
//   }
//   img{
//     width: 100%!important;
//     height: auto!important;
//   }
//   .sc-gKAaRy.bMAizI{
//     position: absolute!important;
//   }
//   input, .sc-gtsrHT.BrLfk{
//     // display: none!important;
//   }
//   .sc-hKFxyN.eufKEV{
//     svg{
//       position: absolute;
//       bottom: -10%;
//       left: 0;
//       right: 0;
//       margin: auto;
//     }
//   }
// }


@media screen and (max-width: 750px){
  .nav-active-board{
    display: none!important;
  }
  .sidebar-hide .navbar-vertical {
    max-width: 100%;
  }
  .form-control, button, .rdp-input-group{
    margin-top: 10px;
  }
  .input-group .form-control{
    margin-top: 0;
  }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-auto {
    width: auto;
  }
  .list-order .form-group{
    margin-bottom: 10px;
  }
  .box-dashboard .col-lg-6{
    width: 50%;
    .col-auto {
      display: none;
    }
  }
  .order-show-col{
    .col-md-2{
      width: 25%!important;
    }
    .col-md-10{
      width: 75%!important;
    }
  }
  #rdp-form-control-example-datepicker{
    margin-top: 0;
  }
  .form-group {
    margin-bottom: 5px;
  }
  .modal-body #rdp-input-group-example-datepicker{
    margin-top: 0;
  }
  .cover-image{
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    height: 170px;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
      position: absolute;
    }
  }
}

@media screen and (max-width: 550px){
  .cover-image{
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    height: auto;
    overflow: hidden;
    img{
      position: inherit;
      left: 0;
      right: 0;
    }
  }
}